<template>
  <div class="reset-pwd">
    <div class="reset-pwd__header">
      <i class="me-icon-back" @click="$router.go(-1)"></i>
      <span class="reset-pwd__header--title" @click="$router.go(-1)">{{
        $t("resetPwd")
      }}</span>
    </div>
    <van-cell-group class="reset-pwd__content">
      <van-field
        type="password"
        v-model="pwd"
        :placeholder="$t('newPwd')"
        label-width="28px"
      >
        <i slot="label" class="me-icon-password reset-pwd--icon" />
      </van-field>
      <van-field
        type="password"
        v-model="repwd"
        :placeholder="$t('repNewPwd')"
        label-width="28px"
        :class="{ error: pwdError }"
      >
        <i slot="label" class="me-icon-password reset-pwd--icon" />
      </van-field>
      <van-button
        type="primary"
        class="reset-pwd--submit"
        :loading="loading"
        @click="handlerSetPwd()"
      >
        <i class="me-icon-check"></i>
      </van-button>
    </van-cell-group>
  </div>
</template>
<script>
import { fnencrypt } from "@/utils/help";
import { forgetPassword } from "@/api/webApi";
export default {
  data() {
    return {
      params: {},
      pwd: "",
      repwd: "",
      loading: false,
      pwdError: false,
    };
  },
  created() {
    this.handlerInitParams();
    console.log(this.$route, "/route");
  },
  methods: {
    async handlerSetPwd() {
      if (!this._checkPwd()) {
        this.$toast.fail();
        this.pwdError = true;
        return;
      } else this.pwdError = false;
      this.params.password = fnencrypt(this.pwd);
      const result = await forgetPassword(this.params);
      if (result && result.code === 200) {
        this.$toast.success();
        this.$router.push({ name: "login", query: { redirect: "home" } });
      } else this.$toast.fail(result.message || "");
    },
    _checkPwd() {
      return this.pwd && this.repwd && this.pwd === this.repwd;
    },
    handlerInitParams() {
      this.params = {
        ...this.$route.query,
        language: localStorage.getItem("langCode") || 1,
      };
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.error{
    &::after{
        border-bottom 1px solid red !important;
    }
}
+b(reset-pwd){
    display: flex;
    height: 94vh;
    background #ffffff;
    flex-flow column;
    +m(icon){
        font-size 19px
    }

    +m(submit){
        width:100%;
        margin-top 2rem
        i{
            font-size 26px
        }
    }
    +e(content){
        padding 0 .9rem;
        border 0;
        margin-top 6rem;
        &::after{
            border-width:0
        }
        .van-cell{
            line-height 40px;
        }
    }
      +e(header) {
    height: 54px;
    line-height: 54px;
    padding: 0 0.9rem;

    .me-icon-back {
      float: left;
    }
  }
}
</style>
