var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-pwd" },
    [
      _c("div", { staticClass: "reset-pwd__header" }, [
        _c("i", {
          staticClass: "me-icon-back",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        }),
        _c(
          "span",
          {
            staticClass: "reset-pwd__header--title",
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("resetPwd")))]
        )
      ]),
      _c(
        "van-cell-group",
        { staticClass: "reset-pwd__content" },
        [
          _c(
            "van-field",
            {
              attrs: {
                type: "password",
                placeholder: _vm.$t("newPwd"),
                "label-width": "28px"
              },
              model: {
                value: _vm.pwd,
                callback: function($$v) {
                  _vm.pwd = $$v
                },
                expression: "pwd"
              }
            },
            [
              _c("i", {
                staticClass: "me-icon-password reset-pwd--icon",
                attrs: { slot: "label" },
                slot: "label"
              })
            ]
          ),
          _c(
            "van-field",
            {
              class: { error: _vm.pwdError },
              attrs: {
                type: "password",
                placeholder: _vm.$t("repNewPwd"),
                "label-width": "28px"
              },
              model: {
                value: _vm.repwd,
                callback: function($$v) {
                  _vm.repwd = $$v
                },
                expression: "repwd"
              }
            },
            [
              _c("i", {
                staticClass: "me-icon-password reset-pwd--icon",
                attrs: { slot: "label" },
                slot: "label"
              })
            ]
          ),
          _c(
            "van-button",
            {
              staticClass: "reset-pwd--submit",
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.handlerSetPwd()
                }
              }
            },
            [_c("i", { staticClass: "me-icon-check" })]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }